<template>
  <div>
    <b-modal ref="deleteDialog" hide-footer title="スケジュール削除">
      <modal-form-delete-job
        :job="job"
        @refetchJob="jobDataRefetch"
        @hideDeleteDialog="hideDialog"
        @hideUpdateDialog="hideDialog"
      />
    </b-modal>
    <b-modal ref="createDialog" hide-footer title="スケジュール作成">
      <modal-form-create-job
        :job="job"
        @refetchJob="jobDataRefetch"
        @hideCreateDialog="hideDialog"
      />
    </b-modal>
    <b-modal ref="editDialog" hide-footer title="スケジュール編集">
      <modal-form-update-job
        :job="job"
        @showDeleteDialog="showDialog"
        @hideUpdateDialog="hideDialog"
      />
    </b-modal>
    <b-row>
      <b-col>
        <b-card>
          <h4>スケジュール絞り込み</h4>
          <div class="row">
            <div class="col-sm-2">
              <label>ロボット選択</label>
            </div>
            <div class="col-sm-10">
              <b-form-checkbox-group
                id="select-robots"
                v-model="robotIds"
                :options="selectRobots"
                name="flavour-1"
              ></b-form-checkbox-group>
            </div>
          </div>
        </b-card>
        <b-card>
          <full-calendar
            defaultView="timeGridWeek"
            locale="local"
            timeZone="Asia/Tokyo"
            selectable="true"
            height="auto"
            :plugins="plugins"
            :header="{left:'today prev,next', center: 'title', right:'dayGridMonth,timeGridWeek,timeGridDay'}"
            :buttonText="{today:'今日', month:'月', week:'週', day:'日', list:'スケジュール'}"
            :events="events"
            @eventClick="eventClick"
            @dateClick="dateClick"
            @select="select"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// -- FullCalendar --
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/locales/ja";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import moment from "moment";

// -- Query --
import gql from "graphql-tag";
import { JOB_QUERY } from "../../components/job-query";
import { ROBOT_QUERY } from "../../components/job-query";

// -- Components --
import ModalFormCreateJob from "../components/ModalFormCreateJob";
import ModalFormUpdateJob from "../components/ModalFormUpdateJob";
import ModalFormDeleteJob from "../components/ModalFormDeleteJob";

// -- const variable --
// TODO: ログインユーザから表示させるロボットIDを取得するようにする。今は固定値。
const ROBOT_ID_INDEX = 0;
const PLAN_COLORS = [
  { plan: "播種", color: "#F4CD69" },
  { plan: "農薬散布1", color: "#AB79E0" },
  { plan: "農薬散布2", color: "#C19DE8" },
  { plan: "収穫", color: "#4BC683" }
];

export default {
  components: {
    FullCalendar,
    ModalFormCreateJob,
    ModalFormUpdateJob,
    ModalFormDeleteJob
  },
  data() {
    return {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        momentTimezonePlugin
      ],
      robots: [],
      robotIds: [],
      robotId: null,
      selectRobots: [],
      jobs: {
        edges: []
      },
      job: {
        index: null,
        data: null
      },
      events: [],
      startDateTime: null,
      endDateTime: null
    };
  },
  watch: {
    jobs: {
      handler: function(newVal, oldVal) {
        this.initEvent();
      },
      deep: true
    },
    robotIds: {
      handler: function(val) {
        this.robotIds = val;
        this.getJobData();
      },
      deep: true
    }
  },
  methods: {
    // --------------------------------
    // カレンダーイベントクリック時
    // --------------------------------
    eventClick: function(info) {
      let index = this.jobs.edges.findIndex(
        nodes => nodes.node.id === info.event.id
      );
      let data = this.jobs.edges[index];
      this.setModalFormVal(index, data);
      this.showDialog("editDialog");
    },
    // --------------------------------
    // 日付クリック時
    // --------------------------------
    dateClick: function(info) {
      let startDate = moment(info.date).format();
      let endDate = moment(info.date)
        .add(30, "m")
        .format();

      let index = null;
      let data = {
        node: {
          plan: null,
          project: null,
          robot: null,
          startDate: startDate,
          endDate: endDate
        }
      };

      this.setModalFormVal(index, data);
      this.showDialog("createDialog");
    },
    // --------------------------------
    // カレンダードラッグ時
    // --------------------------------
    select: function(info) {
      let startDate = moment(info.start).format();
      let endDate = moment(info.end).format();

      let index = null;
      let data = {
        node: {
          plan: null,
          project: null,
          robot: null,
          startDate: startDate,
          endDate: endDate
        }
      };

      this.setModalFormVal(index, data);
      this.showDialog("createDialog");
    },
    // --------------------------------
    // calendar eventの初期化
    // --------------------------------
    initEvent: function() {
      this.events = [];
      let color;
      this.jobs.edges.forEach(element => {
        PLAN_COLORS.forEach(planColor => {
          // planごとにタスクカードを色分け
          if (planColor.plan == element.node.plan.name) {
            color = planColor.color;
          }
        });

        this.events.push({
          id: element.node.id,
          title: element.node.plan.name,
          start: element.node.startDate,
          end: element.node.endDate,
          color: color
        });
      });
    },
    // --------------------------------
    // form入力値のsetter
    // --------------------------------
    setModalFormVal: function(index, jobData) {
      this.job.index = index;
      this.job.data = jobData;
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showDialog: function(target) {
      this.$refs[target].show();
    },
    // --------------------------------
    // modalを閉じる
    // --------------------------------
    hideDialog: function(target) {
      this.$refs[target].hide();
    },
    // --------------------------------
    // 登録済みJobを取得
    // --------------------------------
    getJobData: function() {
      // 【拡張】期間指定
      // FIXME: Fullcalendarのバグで表示されている範囲のデータだけ持ってくることができない
      // https://github.com/fullcalendar/fullcalendar-vue/issues/71
      let startDate = null;
      let endDate = null;

      if (this.robotIds.length != 0) {
        this.$apollo.addSmartQuery("jobs", {
          query: JOB_QUERY,
          variables: {
            robotIds: this.robotIds,
            startDate: startDate,
            endDate: endDate
          }
        });
      } else {
        this.jobs = { edges: [] };
      }
    },
    // --------------------------------
    // Jobを再取得
    // --------------------------------
    jobDataRefetch: function() {
      this.$apollo.queries.jobs.refetch();
    }
  },
  // 今はロボットIDを全件取得して、その中から対象のロボットIDを定数で指定して取得
  // TODO: 何かしらの引数で対象ロボットを指定してロボットIDを取得
  apollo: {
    robots: {
      query: ROBOT_QUERY,
      result({ data }) {
        if (data == undefined || data == null) {
          throw new Error("Failed to get robot info");
        }
        // 初期化
        this.robotIds = [];
        this.selectRobots = [];

        for (let i = 0; i < data.robots.edges.length; i++) {
          const robotId = data.robots.edges[i].node.id;
          this.robotIds.push(robotId);

          // ロボット選択チェックボックス用
          const selectRobot = {
            text: data.robots.edges[i].node.uuid,
            value: robotId
          };
          this.selectRobots.push(selectRobot);
        }

        this.getJobData();
      }
    }
  }
};
</script>

<style>
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
</style>
