import gql from "graphql-tag";

//更新
export const CREATE_JOB = gql`
  mutation CreateJob($input: JobInput!) {
    createJob(input: $input) {
      job {
        id
        plan {
          id
          name
        }
        robot {
          id
          uuid
          ip
          station{
            id
          }
        }
        project {
          id
          name
        }
        field {
          id
          name
        }
        section {
          id
        }
        row {
          id
        }
        startDate
        endDate
      }
      errors {
        field
        messages
      }
    }
  }
`;

//参照
export const UPDATE_JOB = gql`
  mutation UpdateJob($id: ID, $input: JobInput!) {
    updateJob(id: $id, input: $input) {
      job {
        id
        plan {
          id
          name
        }
        robot {
          id
          uuid
          ip
          station{
            id
          }
        }
        project {
          id
          name
        }
        field {
          id
          name
        }
        section {
          id
        }
        row {
          id
        }
        startDate
        endDate
      }
      errors {
        field
        messages
      }
    }
  }
`;

//削除
export const DELETE_JOB = gql`
  mutation DleteJob($id: ID) {
    deleteJob(id: $id) {
      job {
        id
      }
    }
  }
`;
