<template>
  <div>
    <b-row>
      <b-col>
        <label>日付</label>
        <datepicker
          :language="datePickerLocale"
          @closed="setInputJobDate"
          v-model="inputJob.date"
          format="yyyy-MM-dd"
          name="datepicker"
          id="input-id"
          input-class="input-class"
        ></datepicker>
        <br />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" lg="6">
        <b-form-group id="time-form-group" label="開始時間" label-for="start-time">
          <b-form-select
            id="start-time"
            :options="workingTime.startTime"
            @change="changeWorkingTime"
            v-model="inputJob.startTime"
            :state="validateState('startTime')"
            aria-describedby="startTime"
            required
          />
          <b-form-invalid-feedback ref="startTime">{{ error }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="6" lg="6">
        <b-form-group id="time-form-group" label="終了時間" label-for="end-time">
          <b-form-select
            id="end-time"
            :options="workingTime.endTime"
            @change="changeWorkingTime"
            v-model="inputJob.endTime"
            :state="validateState('endTime')"
            aria-describedby="endTime"
            required
          />
          <b-form-invalid-feedback ref="endTime">{{ error }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" lg="6">
        <b-form-group>
          <label for="robot">ロボット</label>
          <b-form-select
            v-model="inputJob.robot"
            :state="validateState('robotId')"
            aria-describedby="robotId"
            required
          >
            <option
              v-for="item in robots.edges"
              :key="item.id"
              :value="item.node"
            >{{item.node.uuid}}</option>
          </b-form-select>
          <b-form-invalid-feedback ref="robotId">{{ error }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="6" lg="6">
        <b-form-group>
          <label for="plan">計画</label>
          <b-form-select v-model="inputJob.plan" required>
            <option v-for="item in plans.edges" :key="item.id" :value="item.node">{{item.node.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" lg="6">
        <b-form-group>
          <label for="project">プロジェクト</label>
          <b-form-select v-model="inputJob.project" required>
            <option
              v-for="item in projects.edges"
              :key="item.id"
              :value="item.node"
            >{{item.node.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="6" lg="6">
        <label>畑名</label>
        <field-form :propField="job.data.node.field" @getField="setField" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "@fullcalendar/core/locales/ja";
import moment from "moment";
// -- DatePicker --
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
// -- Query --
import gql from "graphql-tag";
import {
  PLAN_QUERY,
  PROJECT_QUERY,
  ROBOT_QUERY
} from "../../components/job-query";
import FieldForm from "./form/FieldForm";
import { startOfDay } from "@fullcalendar/core";

const START_WORK_TIME = 0;
const END_WORK_TIME = 24;
const EVERY_MINUTES = 30;
const EVERY_HOURS = 1;

export default {
  components: {
    Datepicker,
    FieldForm
  },
  props: ["job", "errors"],
  data() {
    return {
      startOfDay,
      datePickerLocale: ja,
      plans: [],
      projects: [],
      robots: [],
      workingTime: {
        startTime: [],
        endTime: []
      },
      inputJob: {
        plan: this.job.data.node.plan,
        project: this.job.data.node.project,
        robot: this.job.data.node.robot,
        field: this.job.data.node.field,
        date: null,
        startDate: this.job.data.node.startDate,
        endDate: this.job.data.node.endDate,
        startTime: null,
        endTime: null
      },
      error_valid_pair: {
        job_duplicate: ["startTime", "endTime", "robotId"],
        set_time_order: ["startTime", "endTime"]
      },
      error: ""
    };
  },
  created: function() {
    this.inputJob.date = moment(this.inputJob.startDate).format();
    this.inputJob.startTime = moment(this.inputJob.startDate).format("H:mm");
    this.inputJob.endTime = moment(this.inputJob.endDate).format("H:mm");
    this.initWorkingTime();
  },
  methods: {
    setField: function(value) {
      if (!value) {
        this.field = this.job.data.node.field;
        return;
      }
      this.inputJob.field = value;
    },
    // --------------------------------
    // jobの開始・終了時間の生成
    // --------------------------------
    initWorkingTime: function() {
      for (
        let hour = START_WORK_TIME;
        hour < END_WORK_TIME;
        hour += EVERY_HOURS
      ) {
        for (let minute = 0; minute < 60; minute += EVERY_MINUTES) {
          this.workingTime.startTime.push(
            hour + ":" + ("0" + minute).slice(-2)
          );
          this.workingTime.endTime.push(hour + ":" + ("0" + minute).slice(-2));
        }
      }
    },
    // --------------------------------
    // jobの開始・終了時間の変更時のイベント
    // --------------------------------
    changeWorkingTime: function() {
      let times = this.workingTime.startTime;
      this.workingTime.endTime = times.slice(
        times.indexOf(this.inputJob.startTime)
      );
      this.setInputJobDate();
    },
    // --------------------------------
    // jobの開始・終了日時の格納
    // --------------------------------
    setInputJobDate: function() {
      let date = moment(this.inputJob.date).format("YYYY-MM-DD");
      this.inputJob.startDate = moment(
        date + " " + this.inputJob.startTime
      ).format();
      this.inputJob.endDate = moment(
        date + " " + this.inputJob.endTime
      ).format();
    },
    // --------------------------------
    // jobの内容の取得
    // --------------------------------
    getInputJob: function() {
      return this.inputJob;
    },
    // --------------------------------
    // バリデーション
    // --------------------------------
    validateState(ref) {
      if (this.errors !== null) {
        for (let error of this.errors) {
          let field = error.field;

          if (this.error_valid_pair[field].indexOf(ref) >= 0) {
            for (let message of error.messages) {
              this.error = message;

              return false;
            }
          }
        }
      }

      return;
    }
  },
  apollo: {
    plans: {
      query: PLAN_QUERY,
      result({ data, loading }) {}
    },
    projects: {
      query: PROJECT_QUERY,
      result({ data, loading }) {}
    },
    robots: {
      query: ROBOT_QUERY,
      result({ data, loading }) {}
    }
  }
};
</script>
