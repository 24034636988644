<template>
  <b-form @submit.prevent="deleteJob()">
    <b-button type="submit" size="sm" variant="danger">
      <i class="fa fa-dot-circle-o"></i> 削除しますか
    </b-button>
  </b-form>
</template>

<script>
// -- Query --
import { DELETE_JOB } from "../../components/job-mutation";

// -- Component --
import ModalFormJob from "./ModalFormJob";

export default {
  components: {
    ModalFormJob
  },
  props: ["job"],
  data() {
    return {};
  },
  methods: {
    // --------------------------------
    // ジョブの削除
    // --------------------------------
    deleteJob: function() {
      this.$apollo
        .mutate({
          mutation: DELETE_JOB,
          variables: {
            id: this.job.data.node.id
          }
        })
        .then(result => {
          this.$emit("refetchJob");
          this.hideDialog();
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    },
    hideDialog: function() {
      this.$emit("hideDeleteDialog", "deleteDialog");
      this.$emit("hideUpdateDialog", "editDialog");
    }
  }
};
</script>
