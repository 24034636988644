<template>
  <b-form @submit.prevent="createJob()">
    <modal-form-job ref="modalFormJob" :job="job" :errors="errors" />
    <b-button type="submit" size="sm" variant="primary">
      <i class="fa fa-dot-circle-o"></i> 作成
    </b-button>
  </b-form>
</template>

<script>
// -- Query --
import { CREATE_JOB } from "../../components/job-mutation";

// -- Component --
import ModalFormJob from "./ModalFormJob";

export default {
  components: {
    ModalFormJob
  },
  props: ["job"],
  data() {
    return {
      errors: null
    };
  },
  methods: {
    // --------------------------------
    // ジョブの作成
    // --------------------------------
    createJob: function() {
      let inputJob = this.$refs.modalFormJob.getInputJob();
      this.$apollo
        .mutate({
          mutation: CREATE_JOB,
          variables: {
            input: {
              planId: inputJob.plan.id,
              projectId: inputJob.project.id,
              robotId: inputJob.robot.id,
              fieldId: inputJob.field.id,
              startDate: inputJob.startDate,
              endDate: inputJob.endDate
            }
          }
        })
        .then(result => {
          if (result.data.createJob.errors.length != 0) {
            this.errors = result.data.createJob.errors;
          } else {
            this.hideCreateDialog();
          }
          this.$emit("refetchJob");
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    },
    hideCreateDialog: function() {
      this.$emit("hideCreateDialog", "createDialog");
    }
  }
};
</script>
